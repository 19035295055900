import React from 'react';
import './navbar.css';
import{RiMenu3Line,RiCloseLine} from 'react-icons/ri'
import logo from '../../assets/logo.png'

const Navbar = () => {
  return (
    
    <div className='gpt3__navbar'>
      <div className='gpt3__navbar-links'>
        <div className='gpt3__navbar-links_logo'>
           <img src={logo} alt="logo"/>
        </div>
        <div className='gpt3__navbar-links_container'>
          <p><a href="#Početna">Početna</a></p>
          <p><a href="#O-nama">O nama</a></p>
          <p><a href="#Kontakt">Kontakt</a></p>
          <p><a href="#Usluge">Usluge</a></p>
          <p><a href="#Cena">Proverite cenu</a></p>
        </div>
      </div>
    </div>
  )
}

export default Navbar